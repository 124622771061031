import React, { useMemo, useState, useCallback } from 'react';
import { Button, IconButton } from '@mui/material';
import { Add } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router';
import EditIcon from '@mui/icons-material/Edit';
import Toolbar from '../../../components/toolbar/Toolbar';
import useLocation from '../../../components/hook/useLocation';
import TabsPanel from '../../../components/tabs-panel/TabsPanel';
import ServerSideList from '../../../components/server-side-list/ServerSideList';
import ActivityService from '../../../services/ActivityService';
import ActivityType from '../../../enums/ActivityType';
import EditionType from '../../../enums/EditionType';
import { FLUX_ACTIVITIES, FLUX_TRACKS } from '../../../components/context/FluxContext';
import GlobalResourceSchedule from './GlobalResourceSchedule';
import { useUserChange } from '../../../components/context/UserChangeContext';
import { useToast } from '../../../components/context/toast/ToastContext';
import SwitchW from '../../../components/wrapper/SwitchW';
import _ from 'lodash';
import useTrack from '../../../components/hook/useTrack';

export const isLecture = (type) => {
  const realType = ActivityType.getValue(type);
  return realType === ActivityType.getValue('LECTURE') || realType === ActivityType.getValue('KEYNOTE');
};

const ActivityList = () => {
  const history = useHistory();
  const { t } = useTranslation();
  const { formatCurrency } = useLocation();

  const [data, setData] = useState([]);
  const [activeTab, setActiveTab] = useState(0);
  const { isSecretary, currentUser } = useUserChange();
  const { addToast } = useToast();

  const helperContent = [
    {
      title: t('pages.activityList.helper.scroll.title'),
      content: t('pages.activityList.helper.scroll.content'),
      target: '.scroll-container',
      placement: 'top',
    },
    {
      title: t('pages.activityList.helper.tracks.title'),
      content: t('pages.activityList.helper.tracks.content'),
      target: '#track-list',
    },
  ];

  const changeEnabled = useCallback(
    (dataIndex) => {
      const atv = _.clone(data[dataIndex]);
      ActivityService.changeEnable(atv.id).then((response) => {
        if (response.status === 200) {
          const newData = _.clone(data);
          newData[dataIndex] = response.data;
          setData(newData);
          return;
        }
        addToast({ body: t('toastes.activityConfirmError'), type: 'error' });
      });
    },
    [data, t, addToast]
  );

  const { trackList } = useTrack();

  const columns = useMemo(
    () => [
      {
        name: 'id',
        label: "#",
        options: {
          filter: false,
          sort: true,
        },
      },
      {
        name: 'name',
        label: t('pages.activityList.columns.name'),
        options: {
          filter: false,
          searchable: true,
        },
      },
      {
        name: 'track.name',
        label: t('pages.activityList.columns.track'),
        options: {
          filter: true,
          filterOptions: {
            renderValue: (track) => track,
            names: trackList?.map((item) => item?.name),
            logic: (prop, filters) => filters.includes(prop),
          },
        },
      },
      {
        name: 'price',
        label: t('pages.activityList.columns.price'),
        options: {
          filter: false,
          customBodyRender: (price) => formatCurrency(price),
        },
      },
      {
        name: 'vacancies',
        label: t('pages.activityList.columns.vacancies'),
        options: {
          filter: false,
          customBodyRenderLite: (dataIndex) => (isLecture(data[dataIndex].type) ? '-' : data[dataIndex].vacancies),
        },
      },
      {
        name: 'remainingVacancies',
        label: t('pages.activityList.columns.remainingVacancies'),
        options: {
          searchable: false,
          filter: false,
          sort: false,
          customBodyRenderLite: (dataIndex) => (isLecture(data[dataIndex].type) ? '-' : data[dataIndex].remainingVacancies),
        },
      },
      {
        name: 'type',
        label: t('pages.activityList.columns.type'),
        enum: ActivityType,
        options: {
          searchable: false,
          filter: true,
          filterOptions: {
            renderValue: (type) => t(`enums.activityTypes.${type}`),
            names: ActivityType.enums.map((item) => item?.key),
            logic: (prop, filters) => filters.includes(prop),
          },
          customFilterListOptions: {
            render: (type) => t(`enums.activityTypes.${type}`),
          },
          customBodyRenderLite: (dataIndex) => t(`enums.activityTypes.${data[dataIndex].type}`),
        },
      },
      ...(!isSecretary || currentUser?.admin
        ? [
          {
            name: 'confirmed',
            label: t('pages.activityList.columns.confirmed'),
            options: {
              filter: true,
              filterOptions: {
                renderValue: (activated) => t(`enums.activated.${activated}`),
                names: [true, false],
                logic: (prop, filters) => filters.includes(prop),
                display: 'chip',
              },
              customFilterListOptions: {
                render: (activated) => t(`enums.activated.${activated}`),
              },
              sort: true,
              searchable: true,
              customBodyRenderLite: (dataIndex) => (
                <SwitchW
                  checked={data[dataIndex].confirmed}
                  onChange={(e) => changeEnabled(dataIndex, e.target.checked)}
                  onClick={(e) => e.stopPropagation()}
                  name="preview"
                  primary
                />
              ),
            },
          },
        ]
        : []),
      {
        name: 'speakers',
        label: "Palestrante(s)",
        options: {
          searchable: true,
          filter: false,
          sort: true,
          customBodyRenderLite: (dataIndex) => data[dataIndex].speakers.map(({ speaker }) => speaker.name).join(', '),
        },
      },
      {
        name: 'presentationType',
        label: t('pages.activityList.columns.presentationType'),
        enum: EditionType,
        options: {
          searchable: false,
          filter: true,
          display: 'false',
          filterOptions: {
            renderValue: (type) => t(`enums.editionTypes.${type}`),
            names: EditionType.enums.map((item) => item?.key),
            logic: (prop, filters) => filters.includes(prop),
          },
          customFilterListOptions: {
            render: (type) => t(`enums.editionTypes.${type}`),
          },
          customBodyRenderLite: (dataIndex) => t(`enums.editionTypes.${data[dataIndex].presentationType}`),
        },
      },
      {
        name: 'actions',
        label: ' ',
        options: {
          filter: false,
          sort: false,
          searchable: false,
          viewColumns: false,
          customBodyRenderLite: () => (
            <IconButton tabIndex={-1}>
              <EditIcon />
            </IconButton>
          ),
        },
      },
    ],
    [data, formatCurrency, t, changeEnabled, isSecretary, currentUser?.admin, trackList]
  );

  const options = {
    selectableRows: 'none',
    selectToolbarPlacement: 'none',
    customToolbar: () => (
      <Button variant='contained' onClick={() => history.push('/cli/activity')} color="primary" endIcon={<Add />}>
        {t('pages.activityList.tooltip.add')}
      </Button>
    ),
  };
  
  return (
    <>
      <Toolbar
        title={[t('layouts.sidebar.records'), t('layouts.sidebar.activities')]}
        hasArrowBack
        helperContent={activeTab === 1 && helperContent}
        dislocation="300px"
        isSwipe
      />
      <TabsPanel
        primary
        activeTab={activeTab}
        setActiveTab={setActiveTab}
        tabs={[
          { label: t('pages.activityList.tabs.activities'), enabled: true },
          { label: t('pages.activityList.tabs.schedule'), enabled: true },
        ]}
        panels={[
          <ServerSideList
            key={0}
            {...{
              columns,
              options,
              data,
              setData,
            }}
            enableDefaultUseEffect
            textLabelsCod="activityList"
            defaultOnRowClickURL="/cli/activity"
            defaultService={ActivityService}
            onRowsDeleteErrorToast="toastes.deleteError"
            onRowsDeleteToast="toastes.deletes"
            editionBasedMandatoryField="track.edition.id"
            defaultSortOrder={{ name: 'id', direction: 'desc' }}
            fluxListeners={useMemo(() => [FLUX_TRACKS, FLUX_ACTIVITIES], [])}
          />,
          <GlobalResourceSchedule key={1} />,
        ]}
      />
    </>
  );
};

export default ActivityList;
