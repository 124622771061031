import React, { useEffect } from 'react';
import useLocation from '../hook/useLocation';
import AutoCompleteW from '../wrapper/AutoCompleteW';

const CityAutoComplete = (props) => {
  const {
    defaultOnClickSet, watch, setValue, ...otherProps
  } = props;

  const {
    renderCityList,
  } = useLocation();

  const state = watch('state',  props.defaultValue);

  useEffect(() => {
    setValue('city', props.defaultValue);
  }, [props.defaultValue, setValue, state]);

  return (
    <AutoCompleteW
      options={renderCityList(state)}
      {...otherProps}
    />
  );
};

export default CityAutoComplete;
