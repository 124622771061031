import _ from 'lodash';

export default class FormUtils {
  static CEPMask = [/\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/];

  static cellPhoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  static phoneMask = ['(', /\d/, /\d/, ')', ' ', /\d/, /\d/, /\d/, /\d/, '-', /\d/, /\d/, /\d/, /\d/];

  static RGMask = [/\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/];

  static hourMask = [/\d/, /\d/, ':', /\d/, /\d/];

  static cpfMask = [/\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '.', /\d/, /\d/, /\d/, '-', /\d/, /\d/];

  
  static validateCPF = (cpf) => {
    // Verifica se o CPF é nulo ou indefinido
    if (!cpf) return false;
      
    // Remove caracteres que não sejam dígitos
    cpf = cpf.replace(/[^\d]/g, '');
  
    // Verifica se o CPF tem 11 dígitos após a remoção de caracteres não numéricos
    if (cpf.length !== 11) return false;
  
    // Verifica se todos os dígitos são iguais
    if (/^(\d)\1{10}$/.test(cpf)) return false;
  
    let soma = 0;
    let peso = 10;
  
    // Calcula o primeiro dígito verificador
    for (let n = 0; n < 9; n++) {
      soma += parseInt(cpf[n], 10) * (peso - n);
    }
  
    let resultado = soma % 11;
  
    if (resultado < 2) {
      if (cpf[9] !== '0') return false;
    } else {
      if (cpf[9] !== (11 - resultado).toString()) return false;
    }
  
    soma = 0;
    peso = 11;
  
    // Calcula o segundo dígito verificador
    for (let n = 0; n < 10; n++) {
      soma += parseInt(cpf[n], 10) * (peso - n);
    }
  
    resultado = soma % 11;
  
    if (resultado < 2) {
      if (cpf[10] !== '0') return false;
    } else {
      if (cpf[10] !== (11 - resultado).toString()) return false;
    }
  
    return true;
  };
  

  static removeEmptyFields = (data) => {
    const newData = {};
    _.forOwn(data, (value, key) => {
      if (!(key === '' || value == null)) {
        newData[key] = value;
      }
    });
    return newData;
  };
}
