import GenericService from './GenericService';
import authApi from './AuthApi';

export default class ActivityService extends GenericService {
  static BASE_URL = '/api/activities';

  static async findAllByEdition(editionId) {
    try {
      return await authApi.get(`${this.BASE_URL}/edition/${editionId}`);
    } catch (e) {
      return e.response;
    }
  }

  static async findAllByEditionAndConfirmed(editionId, confirmed) {
    try {
      let url = `${this.BASE_URL}/edition-confirmed/${editionId}?confirmed=${confirmed}`;
      return await authApi.get(url);
    } catch (e) {
      return e.response;
    }
  }

  static async findAvaliableActivities(editionId, registrationId) {
    try {
      return await authApi.get(`${this.BASE_URL}/${editionId}/${registrationId}/avaliable-activities`);
    } catch (e) {
      return e.response;
    }
  }

  static async changeEnable(id) {
    try {
      return await authApi.put(`${this.BASE_URL}/change-confirmed/${id}`);
    } catch (e) {
      return e.response;
    }
  }
}
