import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { CssBaseline, StyledEngineProvider, ThemeProvider } from '@mui/material';
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { Router } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import * as Locales from 'date-fns/locale';
import 'pace-js';
import { enUS, ptBR, esES } from '@mui/material/locale';
import { ToastProvider } from './components/context/toast/ToastContext';
import { composeDefaultTheme, ThemeChangeProvider } from './components/context/ThemeChangeContext';
import useEditionHistory from './components/hook/useEditionHistory';
import { EditionChangeProvider } from './components/context/EditionChangeContext';
import Routes from './Routes';
import { UserChangeProvider } from './components/context/UserChangeContext';
import { GlobalLoadingProvider } from './components/context/GlobalLoadingContext';
import { FluxProvider } from './components/context/FluxContext';
import './App.css';
import HelmetW from './components/wrapper/HelmetW';

const RouterWay = () => {
  const history = useEditionHistory();

  return (
    <Router history={history}>
      <Routes />
    </Router>
  );
};

const App = () => {
  const {t, i18n } = useTranslation();
  const [locale, setLocale] = useState('enUS');
  const [localeText, setLocaleText] = useState({
    previousMonth: t('datePicker.prev'),
    nextMonth: t('datePicker.next'),
  });

  const updateLocale = useCallback((language) => {
    if (language === 'es-ES') {
      setLocale('es');
    } else {
      setLocale(language.replace('-', ''));
    }
  }, []);

  const updateLocaleText = useCallback(() => {
    setLocaleText({
      previousMonth: t('datePicker.prev'),
      nextMonth: t('datePicker.next'),
    });
  }, [t]);

  useEffect(() => {
    updateLocale(i18n.language);
    updateLocaleText();
  }, [i18n.language, t, updateLocale, updateLocaleText]);

  const lt = useMemo(() => {
    if (i18n.language === 'pt-BR') return composeDefaultTheme('light', ptBR);
    if (i18n.language === 'es-ES') return composeDefaultTheme('light', esES);
    return composeDefaultTheme('light', enUS);
  }, [i18n.language]);

  const dt = useMemo(() => {
    if (i18n.language === 'pt-BR') return composeDefaultTheme('dark', ptBR);
    if (i18n.language === 'es-ES') return composeDefaultTheme('dark', esES);
    return composeDefaultTheme('dark', enUS);
  }, [i18n.language]);

  const [currentTheme, setCurrentTheme] = useState(lt);

  useEffect(() => {
    setCurrentTheme(lt);
  }, [lt]);

  return (
    <>
      <HelmetW />
      <StyledEngineProvider injectFirst>
        <ThemeProvider theme={currentTheme}>
          <ToastProvider>
            <FluxProvider>
              <EditionChangeProvider>
                <UserChangeProvider>
                  <GlobalLoadingProvider>
                    <ThemeChangeProvider defaultLight={lt} defaultDark={dt} currentTheme={currentTheme} setCurrentTheme={setCurrentTheme}>
                      <LocalizationProvider adapterLocale={Locales[locale]} dateAdapter={AdapterDateFns} localeText={localeText}>
                        <CssBaseline enableColorScheme />
                        <RouterWay />
                      </LocalizationProvider>
                    </ThemeChangeProvider>
                  </GlobalLoadingProvider>
                </UserChangeProvider>
              </EditionChangeProvider>
            </FluxProvider>
          </ToastProvider>
        </ThemeProvider>
      </StyledEngineProvider>
    </>
  );
};

export default App;
