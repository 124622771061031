import React, { useCallback, useEffect, useMemo, useState } from 'react';
import {
    Box,
    Button, Card,
    CardActions,
    CardContent,
    CardHeader,
    Checkbox,
    FormControlLabel,
    FormGroup,
    Paper,
    Popover,
    Tooltip,
    Typography,
} from '@mui/material';
import * as Colors from '@mui/material/colors';
import _ /* , { round } */ from 'lodash';
import { AddShoppingCart, RemoveShoppingCart } from '@mui/icons-material';
import { useTranslation } from 'react-i18next';
import useRTE from '../../../components/hook/useRTE';
import useLocation from '../../../components/hook/useLocation';
import FlagIcon from '../../../components/flag-icon/FlagIcon';
import SpeakerInfoCard from './SpeakerInfoCard';
import FileService from '../../../services/FileService';
import BoxW from '../../../components/wrapper/BoxW';
import CustomDialog from '../../../components/custom-dialog/CustomDialog';
import Scheduler from '../../../components/scheduler/Scheduler';
import AssembleGrid from "../../activities/assemblegrid/AssembleGrid";

export const SimpleContentDisplay = (props) => {
  const { leftItem, rightItem, width = '100%', textColor, isIcon } = props;

  return (
    <Box alignItems="center" display="flex" flexWrap="wrap" flexDirection="row" width={width} sx={{ color: textColor }}>
      {leftItem && !isIcon ? (
        <Box paddingRight={1}>
          <Typography fontWeight="bold">{`${leftItem}:`}</Typography>
        </Box>
      ) : (
          <Box paddingRight={1}>
              <Typography fontWeight="bold">{leftItem}</Typography>
          </Box>
      )}
      <Box>{rightItem}</Box>
    </Box>
  );
};

export const useClientRect = () => {
  const [rect, setRect] = useState(null);

  const ref = useCallback((node) => {
    if (node !== null) {
      setRect(node.getBoundingClientRect());
    }
  }, []);

  return [rect, ref];
};

const ActivityCard = (props) => {
  const { activity, onCheck, readOnly, conflicts, isPayed } = props;

  const [events, setEvents] = useState([]);
  const [openScheduleDialog, setOpenScheduleDialog] = useState(false);
  const { renderFromState } = useRTE();
  const { formatCurrency } = useLocation();
  const { t } = useTranslation();

  const avatarColor = useMemo(() => _.sample(Colors)[500], []);

  const [speakerSrc, setSpeakerSrc] = useState({});

  useEffect(() => {
    activity.speakers.forEach(({ speaker }) => {
      if (speaker.userProfile && speaker.src == null) {
        FileService.findOne(speaker.userProfile.id).then((response) => {
          if (response.status === 200) {
            // eslint-disable-next-line no-param-reassign
            speaker.src = URL.createObjectURL(
              new Blob([response.data], { type: `image${speaker.userProfile.format === 'svg' ? '/svg+xml' : ''};charset=utf-8` })
            );
            speakerSrc[speaker.id] = speaker.src;
            setSpeakerSrc(_.clone(speakerSrc));
          }
        });
      }
    });
  }, [activity, speakerSrc]);

  useEffect(() => {
    if (activity.schedule.length > 0) {
      setEvents(
        activity.schedule.map(({ startDateTime, endDateTime, allDay, title, color }) => ({
          start: startDateTime,
          end: endDateTime,
          allDay,
          title,
          color,
        }))
      );
    }
  }, [activity.schedule]);

  const [anchorEl, setAnchorEl] = React.useState(null);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const getConflicts = () => {
      if(conflicts){
          let dataConflicted = conflicts.filter((data) => data.activity2.id === activity.id);
          if(dataConflicted.length > 0){
              return (
                  <div>
                      <Card sx={{
                          backgroundColor: 'warning.light',
                          borderColor: 'warning.light', borderWidth: 1, borderStyle: 'solid'
                      }}>
                          <CardContent>
                              <Typography width={"100%"} align={"center"} fontSize={25} fontWeight={"bold"}
                                          marginTop={"30px"} marginBottom={"30px"}>
                                  Atividade selecionada conflita com outra(s) atividade(s) já adquirida(s)/selecionada(s):
                              </Typography>
                              {dataConflicted.map((dataActivity) => (
                                  <>
                                      <Typography variant="subtitle1" color="text.primary">
                                          ID: {`${dataActivity.activity1.id}`} - {`${dataActivity.activity1.activity.name}`}
                                      </Typography>
                                      <AssembleGrid data={dataActivity.activity1.activity}/>
                                  </>
                              ))}
                          </CardContent>
                      </Card>
                  </div>);
          }
      }
  }

  const open = Boolean(anchorEl);
  const id = open ? 'simple-popover' : undefined;

  const [isChecked, setIsChecked] = useState(false);

  return (
    <>
      <CustomDialog
        dialogProps={{ maxWidth: 'lg' }}
        open={openScheduleDialog}
        onClose={() => setOpenScheduleDialog(false)}
        title={t('pages.myRegistration.scheduleTitle')}
        content={
          <BoxW width="100%">
            <Scheduler
              {...{
                events,
                setEvents,
              }}
              readOnly
              validRange={{
                start: activity?.track?.initialDate,
                end: activity?.track?.finalDate,
              }}
            />
          </BoxW>
        }
      />
        {isChecked && (
            getConflicts()
        )}
      <Paper
        // ref={ref}
        elevation={4}
        sx={(theme) => ({
          padding: theme.spacing(2),
          borderRadius: 1.3,
          textAlign: 'center',
          display: 'flex',
          // width: '100%',
          minWidth: '360px',
          minHeight: '550px',
          flexDirection: 'column',
          justifyContent: 'space-between',
        })}
      >
        <CardHeader
          title={activity.name}
          sx={{ p:0 }}
          subheader={
            <Tooltip title={activity?.track?.name} placement="bottom" arrow>
                {activity?.track?.name?.length > 50 ? `${activity?.track.name.substring(0,50)}...`:activity.track.name}
            </Tooltip>
          }
        />
        <CardContent sx={{ height: '80px', display: 'flex', textAlign: 'center',alignItems: 'center', justifyContent: 'center', py:0 }}>
          {activity.description?.blocks[0]?.text !== '' ? (
            <>
              <Tooltip title={t('pages.myRegistration.activityCard.tooltipDescription')} placement="top" arrow>
                <Box
                  sx={{cursor: 'pointer' }}
                  onClick={(e) => handleClick(e)}
                >
                  {`${activity.description?.blocks[0]?.text.substring(0, 50)}...`}
                </Box>
              </Tooltip>
              <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                sx={{ '& .MuiPopover-paper': { sx: { width: '80%' }, md: { width: '40%' } } }}
                anchorOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
                transformOrigin={{
                  vertical: 'top',
                  horizontal: 'left',
                }}
              >
                <Box p={3}>
                  <strong>{t('pages.userActivities.activityCard.description')}:</strong>
                  {renderFromState(activity.description)}
                </Box>
              </Popover>
            </>
          ) : (
            <Box sx={{ alignItems: 'center' }}>{t('pages.myRegistration.activityCard.noDescription')}</Box>
          )}
        </CardContent>
        <CardContent sx={{ p:0 }}>
          <Box width="100%" display="flex" flexWrap="wrap" flexDirection="column">
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.responsible')}
              rightItem={
                activity.responsibleUser ? (
                  <SpeakerInfoCard speakers={[activity.responsibleUser]} avatarColor={avatarColor} />
                ) : (
                  <Typography sx={(theme) => ({ color: theme.palette.error.main })}>
                    {t('pages.myRegistration.activityCard.noResponsible')}
                  </Typography>
                )
              }
            />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.speakers')}
              rightItem={<SpeakerInfoCard speakers={activity.speakers} avatarColor={avatarColor} />}
            />
              { activity.price > 0 && (
                  <SimpleContentDisplay
                      leftItem={t('pages.myRegistration.activityCard.vacancies')}
                      rightItem={`${activity.remainingVacancies}/${activity.vacancies}`}
                  />
              )}
            <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.workload')} rightItem={activity.workload} />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.type')}
              rightItem={t(`enums.activityTypes.${activity.type}`)}
            />
            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.presentationType')}
              rightItem={t(`enums.editionTypes.${activity.presentationType}`)}
            />

            { (activity.presentationType === 'LIVE' || activity.presentationType === 'HYBRID') && (
                <SimpleContentDisplay
                    leftItem={t('pages.myRegistration.activityCard.local')}
                    rightItem={t(`${activity.place.name}`)}
                />
            )}
            {(activity.presentationType === 'HYBRID' || activity.presentationType === 'ONLINE') && (
                <SimpleContentDisplay
                    leftItem={t('pages.userActivities.activityCard.webPlace')}
                    rightItem={isPayed ?
                        activity.placeUrl
                    :
                        <Typography align={"left"} sx={(theme) => ({color: theme.palette.error.main})}>
                            {`${t('pages.userActivities.activityCard.availabilityLink')}`}
                        </Typography>
                }
                />
            )}

            <SimpleContentDisplay
              leftItem={t('pages.myRegistration.activityCard.language')}
              rightItem={
                <Box display="flex" alignItems="center">
                  {activity.language}
                  {activity.languageFlag && (
                    <Box paddingLeft={1}>
                      <FlagIcon country={activity.languageFlag} height="25px" />
                    </Box>
                  )}
                </Box>
              }
            />
            <SimpleContentDisplay leftItem={t('pages.myRegistration.activityCard.price')} rightItem={activity.price === 0 ? 'Gratuito' : formatCurrency(activity.price)} />

            <AssembleGrid data={activity} />
          </Box>
        </CardContent>
        <CardActions disableSpacing sx={{ display: 'flex', justifyContent: 'center', p:0 }}>
          {!readOnly && (
            <Button variant="contained" color={isChecked ? 'error' : 'primary'} fullWidth>
              <FormGroup>
                <FormControlLabel
                  sx={{ color: 'white', width: '280px', ml:0, justifyContent: 'center' }}
                  label={isChecked ? t('pages.myRegistration.activityCard.removeFromCart') : t('pages.myRegistration.activityCard.includeInPurchase')}
                  labelPlacement="start"
                  control={
                    <Checkbox
                      sx={{ color: 'white' }}
                      onChange={(e) => {
                        setIsChecked(e.target.checked);
                        // eslint-disable-next-line no-unused-expressions
                        onCheck && onCheck(activity, e.target.checked);
                      }}
                      icon={<AddShoppingCart />}
                      checkedIcon={<RemoveShoppingCart sx={{ color: 'white' }} />}
                    />
                  }
                />
              </FormGroup>
            </Button>
          )}
        </CardActions>
      </Paper>
    </>
  );
};

export default ActivityCard;
