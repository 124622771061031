import React, { useEffect, useState } from 'react';
import { Helmet } from 'react-helmet';

const HelmetW = (props) => {
  const { title } = props;

  const [favIcon, setFavIcon] = useState('/grupo_161.svg');

  useEffect(() => {
    window.matchMedia('(prefers-color-scheme: dark)').addEventListener('change', (event) => {
      setFavIcon(event.matches ? '/grupo_161.svg' : '/grupo_160.svg');
    });
  }, []);

  const getTitle = () => {
    if (Array.isArray(title)) {
      if (typeof title[title.length - 1] === 'object') return title[title.length - 1].title;
      return title[title.length - 1];
    }
    if (typeof title === 'object') return title.title;
    return title;
  };

  // Definição da política CSP com o domínio externo para imagens
//   const csp = `
//   default-src 'self';
//   script-src 'self';
//   style-src 'self' 'unsafe-inline' https://fonts.googleapis.com https://themes.googleusercontent.com;
//   font-src 'self' data: https://fonts.gstatic.com;
//   img-src 'self' data: blob: https://hatscripts.github.io;
//   connect-src 'self' https://*.latinoware.org  http://*:8080;
//   object-src 'self';  
//   frame-src 'self' data: blob: https://*.latinoware.org;
// `;
// <meta httpEquiv="Content-Security-Policy" content={csp} />
  return (
    <Helmet>
      {title && <title>{getTitle()}</title>}
      <link rel="icon" type="image/png" href={favIcon} />
    </Helmet>
  );
};

export default HelmetW;
