import React, {useState} from 'react';
import { Box } from '@mui/material';
import { Controller, useForm } from 'react-hook-form';
import { useTranslation } from "react-i18next";
import {useHistory} from "react-router";
import * as yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";
import PasswordStrengthBar from 'react-password-strength-bar';
import Form from '../../../components/form-components/Form';
import TitleCard from "../../../components/title-card/TitleCard";
import { useToast } from "../../../components/context/toast/ToastContext";
import HelmetW from "../../../components/wrapper/HelmetW";
import FormUtils from "../../../utils/FormUtils";
import UserService from "../../../services/UserService";
import ButtonW from "../../../components/wrapper/ButtonW";
import BoxW from "../../../components/wrapper/BoxW";
import ShowHidePassword from "../../../components/form-components/ShowHidePassword";

import useFormUtils from "../../../components/hook/useFormUtils";
import {useUserChange} from "../../../components/context/UserChangeContext";

const ChangePassword = () => {
    const { t } = useTranslation();
    const { addToast } = useToast();
    const { userEmail, currentUser } = useUserChange();

    const history = useHistory();

    const [passwordStrength, setPasswordStrength] = useState(0);

    const { scoreWords } = useFormUtils();

    function validatePasswordStrength() {
        return passwordStrength > 1;
    }

    const schema = yup.object().shape({
        new_password: yup.string().ensure().required()
            .test('passwordMin', '', (value) => value.length >= 8)
            .test('passwordStrength', '', () => validatePasswordStrength()),
        passwordConfirmation: yup.string().ensure().required().test('matchPassword', '', (value, context) =>
            value === context.parent.new_password
        ),
    });

    const defaultValues = {
        new_password: '',
        passwordConfirmation: ''
    };

    const {
        control,
        handleSubmit,
        formState,
        watch,
    } = useForm({
        mode: 'onBlur',
        resolver: yupResolver(schema),
        defaultValues
    });

    const { errors } = formState;

    const handleSendNewPassword = (formUser) => {
        const form = FormUtils.removeEmptyFields(formUser);
        UserService.changePassword(form.new_password, userEmail).then((response) => {
            if (response.status === 200) {
                addToast({
                    message: t('toastes.changePassword'),
                    type: 'sucess',
                });

                history.push('/home');

            } else {
                addToast({
                    message: t('toastes.changePasswordError'),
                    type: 'error',
                });
            }

        }).catch(() => {
            addToast({
                message: t('pages.changePassword.errorMessage'),
                type: 'error',
            });
        });
    };

    return (
        <>
            <Box
                p={2}
                flexDirection="row"
                display="flex"
                flexWrap="wrap"
                alignItems="center"
                justifyContent="center"
                height="calc(50vh - 64px)"
            >
                <Box
                    p={1}
                    width="100%"
                    display="flex"
                    flexDirection="column"
                    justifyContent="center"
                    alignItems="center"
                    sx={(theme) => ({
                        [theme.breakpoints.down('sm')]: {
                            width: '100vw',
                            height: '100%',
                            overflow: 'auto',
                        },
                    })}
                >
                    {
                        currentUser !== null && currentUser !== undefined && currentUser.alterPassword ?
                            (t('pages.changePassword.messageTitleReset')) : (t('pages.changePassword.messageTitleRedefine'))
                    }

                </Box>
            </Box>
            <HelmetW title={t('pages.changePassword.toolbar.changePassword')} />
            <Box
                sx={{
                    width: '100%',
                    height: 'calc(10vh - 64px)',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    position: 'relative',
                    top: 0,
                    left: 0,
                }}
            >
                <Box
                    sx={{
                        width: '90%',
                        maxWidth: '720px',
                        marginLeft: '1rem',
                        marginRight: '1rem',
                        height: 'min-content',
                    }}
                >
                    <TitleCard
                        title={t('pages.changePassword.toolbar.changePassword')}
                        cardProps={{ elevation: 8 }}
                        boxProps={{ p: 1 }}
                    >
                        <Form
                            name="changePasswordForm"
                            onSubmit={handleSubmit(handleSendNewPassword)}
                        >
                            <Box display="flex" flexDirection="row" p={1}>
                                <BoxW width="50%" p={1} sx={{ flex: 1 }}>
                                    <Controller
                                        name="new_password"
                                        render={({ field }) => (
                                            <ShowHidePassword
                                                label={t('pages.changePassword.new_password')}
                                                {...field}
                                                error={errors?.new_password}
                                                required
                                            />
                                        )}
                                        control={control}
                                        rules={{ required: true }}
                                    />
                                    <PasswordStrengthBar
                                        password={watch('new_password', '')}
                                        minLength={8}
                                        scoreWords={scoreWords}
                                        shortScoreWord={t('pages.autoRegistration.password.tooShort')}
                                        onChangeScore={(score) => setPasswordStrength(score)}
                                    />
                                </BoxW>
                                <BoxW width="50%" p={1} sx={{ flex: 1 }}>
                                    <Controller
                                        name="passwordConfirmation"
                                        render={({ field }) => (
                                            <ShowHidePassword
                                                label={t('pages.autoRegistration.password.passwordConfirmation')}
                                                {...field}
                                                error={errors?.passwordConfirmation}
                                                required
                                            />
                                        )}
                                        control={control}
                                        rules={{ required: true }}
                                    />
                                </BoxW>
                            </Box>
                            <Box display="flex" flexDirection="row" p={1} width="100%" justifyContent="center">
                                <Box p={1} width="fit-content">
                                    <ButtonW fullWidth primary type="submit">
                                        {t('pages.changePassword.save')}
                                    </ButtonW>
                                </Box>
                            </Box>
                        </Form>
                    </TitleCard>
                </Box>
            </Box>
        </>
    );
};

export default ChangePassword;
