import {Grid, Typography} from "@mui/material";
import React, {useEffect, useState} from "react";
import {SimpleContentDisplay} from "../../registrations/my-registration/ActivityCard";
import useLocation from "../../../components/hook/useLocation";
import {useTranslation} from "react-i18next";

const AssembleGrid = (props) => {

    const { t } = useTranslation();
    const { formatLocaleDateString, formatLocaleTimeString } = useLocation();

    const [activityData, setActivityData] = useState(props.data);

    useEffect(() => {
        if(props.data !== null && props.data !== undefined){
            props.data.schedule = props.data.schedule.sort((a, b) => new Date(a.startDateTime) - new Date(b.startDateTime));
            setActivityData(props.data);
        }else{
            return null;
        }
    }, [props]);

    return (
      <>
          <Typography width={"100%"} align={"center"} fontSize={25} fontWeight={"bold"} marginTop={"30px"} marginBottom={"10px"}>
              {t('pages.editCertificate.help.activity.schedule')}
          </Typography>
          { activityData.schedule.length <= 0 ? (
                <Typography sx={(theme) => ({color: theme.palette.error.main})}>
                    {t('pages.myRegistration.activityCard.noSchedule')}
                </Typography>
            ) : (
                <Grid container px={1} pt={1} spacing={1} justifyContent={"space-between"} columns={{ xs: 4, sm: 8, md: 12 }}>
                    {activityData.schedule.map((schedule, index) => (
                        <Grid item xs={6} sm={6} md={6} lg={6} key={index} marginBottom={"30px"}>
                            <SimpleContentDisplay leftItem={t('pages.editCertificate.help.activity.data')} rightItem={formatLocaleDateString(schedule.startDateTime)}/>
                                { new Date(schedule.startDateTime).getHours() === 0 && new Date(schedule.endDateTime).getHours() === 0 ?
                                    <>
                                        <Typography align={"left"} sx={(theme) => ({color: theme.palette.error.main})}>{`${t('pages.editCertificate.help.activity.grid')}`}</Typography>
                                        <SimpleContentDisplay leftItem={t('pages.editCertificate.help.activity.start')} rightItem={"07:00"}/>
                                        <SimpleContentDisplay leftItem={t('pages.editCertificate.help.activity.end')} rightItem={"21:30"}/>
                                    </>
                                    :
                                    <>
                                        <SimpleContentDisplay leftItem={t('pages.editCertificate.help.activity.start')} rightItem={formatLocaleTimeString(schedule.startDateTime)+'hs'}/>
                                        <SimpleContentDisplay leftItem={t('pages.editCertificate.help.activity.end')} rightItem={formatLocaleTimeString(schedule.endDateTime)+'hs'}/>
                                    </>
                                }
                        </Grid>
                        ))
                    }
                    </Grid>
              )
          }
      </>
    );
}

export default AssembleGrid;