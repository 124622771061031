/* eslint-disable react/no-unescaped-entities */
import { useTranslation } from 'react-i18next';
import { createContext, useEffect, useMemo, useState } from 'react';
import { Badge, Box, Button, Paper } from '@mui/material';
import Toolbar from '../../components/toolbar/Toolbar';
import StepperW from '../../components/wrapper/StepperW';
import { useParams } from 'react-router';
import { ShoppingCart } from '@mui/icons-material';
import { useEditionChange } from '../../components/context/EditionChangeContext';
import { isMobile } from 'react-device-detect';
import Step1 from './steps/step1';
import Step2 from './steps/step2';
import Step3 from './steps/step3';
import Step4 from './steps/step4';
import Step6 from './steps/step6';
import ActivityService from '../../services/ActivityService';
import { isLecture } from '../activities/list-activities/ActivityList';
import { useToast } from '../../components/context/toast/ToastContext';
import { useFlux } from '../../components/context/FluxContext';

export const ManipulateStep = createContext({
  activeStep: 0,
  setActiveStep: () => {},
  steps: ['', ''],
  stepValues: {},
  updateStepValues: () => {},
  PostDataEdit: () => {},
  PostData: () => {},
});

export const CartContext = createContext({
  cart: [],
  addItem: () => {},
  removeItem: () => {},
  updateCart: () => {},
  includedActivities: [],
  addIncludedActivities: () => {}
});

const Registration = () => {
  const { t } = useTranslation();
  const { step } = useParams();
  const [stepValues, setStepValues] = useState({});
  const { currentLogo, currentEdition } = useEditionChange();

  const { addToast } = useToast()

  const { activitiesUpdateDate } = useFlux();
  const event = currentEdition ? {
    ...currentEdition,
    title: currentEdition?.shortName,
    description: currentEdition?.description,
    image: currentLogo,
    enableHalfPrice: currentEdition?.registrationType?.price === 0 ? false: true,
    price: currentEdition?.registrationType?.price,
    endRegistration: currentEdition?.registrationType?.finalDateTime,
  } : {
    // ...currentEdition,
    title: "Latinoware 2024",
    description: t('pages.registration.step2.description'),
    image: currentLogo,
    price: 0,
    endRegistration: new Date("2024-11-20T00:00:00.000Z"),
  };

  const [activeStep, setActiveStep] = useState(0);

  const steps = [
    t('pages.registration.steps.step1'),
    t('pages.registration.steps.step2'),
    t('pages.registration.steps.step3'),
    t('pages.registration.steps.step4'),
    t('pages.registration.steps.step6'),
  ];

  const breadcrumbs = [
    { title: 'Inscrições', url: '/home' },
    ...steps.slice(0, activeStep + 1).map((label, index) => {
      return { title: label };
    }),
  ];

  useEffect(() => {
    setActiveStep(parseInt(step) || 0);
  }, [step]);

  const updateStepValues = (step, values) => {
    setStepValues((prevValues) => ({ ...prevValues, [step]: values }));
  };

  // :::::::::::::::::::::::::::: CART :::::::::::::::::::::::::::: //

  const [cart, setCart] = useState(localStorage.getItem('cartItems') ? JSON.parse(localStorage.getItem('cartItems')) : []);
  const [includedActivities, setIncludedActivities] = useState([]);

  const updateCart = (newCart) => {
    setCart(newCart);
    localStorage.setItem('cartItems', JSON.stringify(newCart));
  };

  const addItem = (item) => {
    const newItem = {
      id: item.id,
      name: item.name ? item.name : item.title,
      price: item.price,
      track: item.track ?{
        name: item.track.name,
        calendarColor: item.track.calendarColor,
      } : {},
      description: item.description,
      speakers: [],
      schedule: [],
    };

    const newCart = [...cart, newItem];

    setCart(newCart);
    updateCart(newCart);
  };

  const removeItem = (id) => {
    if (cart.length > 0) {
      const newCart = cart.filter((item) => item.id !== id);
      setCart(newCart);
      updateCart(newCart);
    }
  };

  const addIncludedActivities = (items) => {
    setIncludedActivities(items);
  }

  const [activities, setActivities] = useState([]);
 
  const nonLectures = useMemo(() => activities.filter((activity) => !isLecture(activity.type) && activity.remainingVacancies > 0), [activities]);
  const lectures = useMemo(() => activities.filter((activity) => isLecture(activity.type)).sort((a, b) => a.id - b.id), [activities]);

  useEffect(() => {
    if (!currentEdition) return;
    ActivityService.findAllByEditionAndConfirmed(currentEdition.id, true).then((response) => {
      if (response.status === 200) {
        setActivities(response.data);
      }
    });
  }, [currentEdition, t, activitiesUpdateDate]);

  useEffect(() => {
    if(lectures.length > 0){
      addIncludedActivities(lectures);
    }
  }, [lectures]);

  useEffect(() => {
    const syncCart = () => {
      if(cart?.length > 0) {
        const cartNotInActivities = cart.filter(cartItem => 
          !activities.some(activity => activity.id === cartItem.id)
        );
        
        if(cartNotInActivities?.length > 0) {
          for(let itemRevoke of cartNotInActivities) {
            addToast({ body: t('toastes.activityDisabled', { activityName: itemRevoke?.name }), type: 'error' });
            if (cart.length > 0) {
              const newCart = cart.filter((item) => item.id !== itemRevoke?.id);
              setCart(newCart);
              updateCart(newCart);
            }
          }
        }
      }
    }
    syncCart();
  }, [cart, activeStep, activities, addToast, t])

  return (
    <>
      <Toolbar title={breadcrumbs} hasArrowBack />
      <Box p={{ xs: 1, sm: 2 }}>
        <Paper elevation={3} sx={{ p: 3 }}>
          <ManipulateStep.Provider value={{ activeStep, setActiveStep, steps, stepValues, updateStepValues }}>
            <CartContext.Provider value={{ cart, includedActivities, updateCart, addItem, removeItem, addIncludedActivities }}>
              <Box flexDirection="row" display={{ xs: 'none', sm: 'flex' }} justifyContent="space-between" alignItems="center" pl={1}>
                <StepperW steps={steps} activeStep={activeStep} alternativeLabel={isMobile} />
                { activeStep !== 4 && 
                  <Button variant="contained" color="secondary" size="large" sx={{ py: 2, mr: 1, ml: 5 }}>
                    <Badge badgeContent={`${cart?.length}`} color="error">
                      <ShoppingCart fontSize="large" />
                    </Badge>
                  </Button>                
                }
              </Box>
              <Box pl={{ xs: 0, sm: 2 }}>
                {/* Cadastro */}
                {activeStep === 0 && <Step1 item={event} />}
                {/* Atividades adicionais */}
                {activeStep === 1 && <Step2 item={event} cart={cart} nonLectures={nonLectures} lectures={lectures} />}
                {/* Carrinho */}
                {activeStep === 2 && <Step3 item={event} removeItem lectures={lectures} />}
                {/* Pagamento */}
                {activeStep === 3 && <Step4 />}
                {/* Finalizado */}
                {activeStep === 4 && <Step6 item={event} />}
              </Box>
            </CartContext.Provider>
          </ManipulateStep.Provider>
        </Paper>
      </Box>
    </>
  );
};

export default Registration;
