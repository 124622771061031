import React, { useState } from 'react';
import { DatePicker } from '@mui/x-date-pickers'
import { TextField } from '@mui/material';
import { useTranslation } from 'react-i18next';
import useFormUtils from '../hook/useFormUtils';

const DatePickerW = (props) => {
  const { error, onChange, onBlur: externalOnBlur, ...otherProps } = props;
  const { getHelperText } = useFormUtils();
  const { t, i18n} = useTranslation();
  const [isFilled, setIsFilled] = useState(false);
  const [isDateValid, setIsDateValid] = useState(true);
  
  const validateDate = (dateString) => {
    let regex;
    if (i18n.language === 'en-US') {      
      regex = /^(0[1-9]|1[0-2])\/(0[1-9]|[12][0-9]|3[01])\/(19|20)\d\d$/;
    } else {
      regex = /^(0[1-9]|[12][0-9]|3[01])\/(0[1-9]|1[0-2])\/(19|20)\d\d$/;
    }
    return regex.test(dateString);
  };

  const handleDateChange = (date) => {    
    if (date) {
      setIsFilled(true);
    } else {
      setIsFilled(false);
    }
    onChange(date);
  };

  const handleBlur = (event) => {
    const dateValue = event.target.value;

    if (dateValue) {
        setIsFilled(true);
        setIsDateValid(validateDate(dateValue));
    } else {
        setIsFilled(false);
        setIsDateValid(true);
    }

    // Caso haja alguma ação adicional que você queira realizar em `onBlur`:
    if (externalOnBlur) externalOnBlur();
  };

  return (
    <DatePicker
      renderInput={(params) => (
        <TextField
          {...params}
          InputLabelProps={{
            shrink: true,
          }}
          inputProps={{
            ...params.inputProps,
            placeholder: t('datePicker.placeHolderInputDate'),
          }}
          fullWidth
          variant="outlined"
          error={error != null || !isDateValid}
          helperText={isDateValid ? getHelperText(error) : t('yup.invalidDate')}
          required={isFilled}
          onBlur={handleBlur} // Adicionado aqui
        />
      )}
      onChange={handleDateChange}
      {...otherProps}
    />
  );
};

export default DatePickerW;
